import { NewsletterPopupStoryblok } from "@/lib/types/storyblok-blok-types";
import { SubscriptionResult } from "./useSubscribeToNewsletter";
const NewsletterResult = ({
  blok,
  result,
}: {
  blok: NewsletterPopupStoryblok;
  result: SubscriptionResult;
}) => {
  if (!blok) return null;

  return (
    <>
      <p>Thank you for subscribing!</p>
    </>
  );
};
export default NewsletterResult;
