import { useEffect, useState } from "react";
/**
 *
 * @param SECONDS - Expects a number representing how many seconds of delay.
 * @param PAGES_VISITED - Expects a number representing the number of pages visited before timer is triggered
 * @returns a boolean representing if the timer has passed or not
 */
export const useTimeOut = (
  SECONDS: number,
  PAGES_VISITED?: number,
): boolean => {
  const [hasTimePassedState, setHasTimePassedState] = useState(false);

  useEffect(() => {
    if (PAGES_VISITED !== undefined && PAGES_VISITED < 2) {
      return;
    }

    const timeoutID = setTimeout(() => {
      setHasTimePassedState(true);
    }, SECONDS * 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [SECONDS, PAGES_VISITED]);

  // Return the state and the function to increment pages visited
  return hasTimePassedState;
};
